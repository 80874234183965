<template>
     <div class="admin-section profileSettings">
         <div class="app-main__inner">
         <div class="row">
          <div class="col-md-12 col-sm-12 fullWidth">
              <div class="app-page-title dashboard-title">
                  <div class="page-title-wrapper">
                      <div class="page-title-heading">
                          <div class="page-title-icon">
                              <i class="pe-7s-id icon-gradient bg-happy-itmeo">
                              </i>
                          </div>
                          <div>Settings</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        

         <div class="row userProfile m-3">
        <!-- <app-SelectedPlan v-if="email!='munir.muhammadsaeed@gmail.com'"/> -->
        <app-ProductSelectedPlan/>
        </div>

        <div class="row" id="userProfileDiv">
             <div class="col-md-12 mt-1 settings col-sm-12 fullWidth">
                 <div class="main-card mb-3 card">
                <div class="card-body ml-4 mr-4" >
                <app-Register :userProfile="'CurrentUser'" />
                </div>
                </div>
             </div>
         </div>

         <div class="row">
             <div class="col-md-12 mt-1 settings col-sm-12 fullWidth">
                 <div class="main-card mb-3 card">
                <div class="card-body ml-5 mr-4 specialPrepend">
                    <div class="col-12" ref="referralLink"> <h2>Referral link</h2></div>
                    <b-input-group class="copyShare"  prepend="Link">
                    <b-form-input id="prefilereferralUrl" v-model="referralUrl" ></b-form-input>
                    <b-input-group-append>
                    <b-button class="pl-2 pr-2" id="copy" @click="copyLinkToCopyProf()">Copy</b-button>
                    <b-button class="pl-2 pr-2" v-b-modal.modal-EmailPopup variant="success">Share</b-button>
                    </b-input-group-append>
                </b-input-group>
                    <!-- <p class="referralUrl">{{referralUrl}}</p> -->
                    <input type="text" v-model="referralUrl" id="referralUrl">
                    <!-- <div class="offset-2 col-8">
                        <b-button class="pl-5 pr-5 mr-4" @click="copyLinkToCopy()" variant="info">Click to Copy</b-button>
                    
                         <b-button class="pl-5 pr-5"  v-b-modal.modal-EmailPopup variant="success">Click to Share</b-button>
                     </div> -->
                    <!-- <b-button @click="copyLinkToShare()" variant="primary">Click to Share</b-button> -->
                </div>
                </div>
             </div>
         </div>

         

         <div class="row" ref="userPersonal">
             <div class="col-md-12 mt-1 settings col-sm-12 fullWidth">
                 <div class="main-card mb-3 card">
                <div class="card-body ml-4 mr-4">
                <app-UserPersonalInfo :userId="user_id"/>
                </div>
                </div>
             </div>
         </div>
         
         </div>
         <b-modal
      id="modal-EmailPopup"
      ref="modal-EmailPopup"
      title="Share Referrence link"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      okTitle="Send"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Email"
          label-for="email-input"
          invalid-feedback="Email is required"
          :state="emailState"
        >
          <b-form-input
            id="email-input"
            v-model="shareemail"
            :state="emailState"
            type="email"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
     </div>
</template>
<script>
import SelectedPlan from '../home/PriceSection'
import ProductSelectedPlan from '../home/ProductPriceSection'
import Register from '../Register'
import UserPersonalInfo from './userPersonalInfo'
import { mapActions,mapGetters } from 'vuex'
export default {
    data(){
        return{
            user_id:null,
            email:'',
            shareemail:'',
            referralUrl: 'https://practice.pteclasses.com/?referral=',//'https://localhost:8080/?referral=',
            emailState: null,
        }
    },
    components:{
        'app-SelectedPlan':SelectedPlan,
        'app-ProductSelectedPlan':ProductSelectedPlan,
        'app-Register':Register,
        'app-UserPersonalInfo':UserPersonalInfo
    },
    beforeMount(){
        ////debugger
        this.user_id=this.$ls.get('user').user_id
        this.email=this.$ls.get('user').email_id
        const encryptValue='diresu'+this.user_id
        let encryptedUserId=Math.random().toString(36).slice(-10)+'1-0'+encryptValue+'1-0'+Math.random().toString(36).slice(-10)+Math.random().toString(36).slice(-10);
        // let encryptedUserId = this.CryptoJS.AES.encrypt(JSON.stringify({ encryptValue }), "Think higher").toString()
        // encryptedUserId=encryptedUserId.replaceAll('/','-A12-')
        // encryptedUserId=encryptedUserId.replaceAll('\\','-B12-')
       // const encryptedEmail = this.CryptoJS.AES.encrypt(this.email, "Think higher").toString()
        //console.log(encryptedUserId)
        ////console.log(encryptedEmail)
        this.referralUrl+=encryptedUserId//+'-'+encryptedEmail;
        //console.log(this.referralUrl)
        // encryptedUserId=encryptedUserId.replaceAll('-A12-','/')
        // encryptedUserId=encryptedUserId.replaceAll('-B12-','\\') 
        // //console.log(this.CryptoJS.AES.decrypt(encryptedUserId, "Think higher").toString(this.CryptoJS.enc.Utf8))

    },
    methods:{
        ...mapActions({
            sendEmail: 'speakmodule/saveAnswer',
        }),
        ...mapGetters({
            getResponse: 'speakmodule/AnswerResponse',
        }),
        copyLinkToCopyProf(){
            /* Get the text field */
            var copyText = document.getElementById("prefilereferralUrl");
            //console.log(copyText)
            /* Select the text field */
            //copyText.select();
            /* For mobile devices */

            /* Copy the text inside the text field */
           // document.execCommand("copy");
            navigator.clipboard.writeText(copyText);
        },
        copyLinkToShare(){
            var data={
                referralLink:this.referralUrl,
                email:this.shareemail,
                url:'users/sendReferralEmail'
            }
           // alert('test')
            this.sendEmail(data).then(()=>{
                const response=this.getResponse()
                //console.log(response)
                if(response.success==1){
                    this.$alert(response.message)
                }
            })
        },
        checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.emailState = valid
        if(valid){
            this.emailState= this.shareemailvalidation()
            this.emailState=valid
        }
        return valid
      },
      shareemailvalidation: function (){
                    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm
                    const email = this.shareemail
                   // ////console.log(email)
                    if(!re.test(email))
                    {
                        return false
                    }
                    else return true
        },
      emailvalidation: function (){
                    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm
                    const email = this.email
                   // ////console.log(email)
                    if(!re.test(email))
                    {
                        return false
                    }
                    else return true
        },
      resetModal() {
        this.email = ''
        this.emailState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-EmailPopup')
          this.copyLinkToShare()
        })
      }
    },
    mounted(){
        if(this.$ls.get('userSettings')!=null){
            if(this.$ls.get('userSettings')=='removeAccount') {
                return this.$alert("You can remove your account from User Information section.").then(()=>{
                    //if(this.$refs['userPersonal']!=null)this.$refs['userPersonal'].scrollIntoView({behavior:'smooth'});
                    document.getElementById('userProfileDiv').scrollIntoView({ behavior: "smooth" });
                });
            }
         }
    }
}
</script>
<style scoped>
.userProfile .price-table
{
    background: none!important;
    padding-top: 10px!important;
}

</style>
<style>
.admin-section h2{
font-size: 25px;
color: #1e467f !important;
padding-bottom: 20px;
}
.admin-section .heading-center p {
    /* font: 14px 'Montserrat'; */
    font: 14px 'Montserrat'!important;
}
.userProfile.m-3 .price-table .box{
    width: 95%;
}
.referralUrl
{
    text-transform: none;
    color: rgb(70, 70, 70);
}
#referralUrl{
    opacity: 0!important;
    position: absolute;
}
.specialPrepend div.input-group-prepend
{
    padding: 0px!important;
}
.specialPrepend .input-group input
{
    max-width: 500px!important;
}
.specialPrepend .input-group button{
    min-width: 100px!important;
        margin: 0px!important;
}
.specialPrepend #copy 
{
    background: #273045;
    border: #273045 1px solid;
    color: #fff!important;
}
.specialPrepend #copy:hover, .specialPrepend #copy:focus, .specialPrepend #copy:visited
{
    background: #000000;
    border: #000 1px solid;
    color: #fff!important;
}
</style>