<template>
    <div class="row userProfileInfo">
        <div class="col-12">
        <h2>Personal Information</h2>
        <form @submit.prevent="saveUpdateUserPersonalInfo">
            <div class="imageDivOutPut col-md-4 d-none d-md-block">
                    <output class="imageOutPut" @click="selectImage()">
                    <img  :src="previewUrl" v-if="previewUrl">
                    <p v-else>Click to add your Profile Picture for Mock Test</p>
                    </output>
                <b-form-file id="imageInput" v-show="false" type="file" @change="onFileChange" accept="image/*">
                </b-form-file>

            </div>
        <div class="col-md-8 col-sm-12 fullWidth">
            
            <div class="form-group">
                <label for="residence">Country of Residence</label>
                <b-form-select v-model="userPersonalInfo.residence"  name="residence" class="form-control" :options="countriesList" >
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Please select country of residence --</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
        </div>
        <div class="col-md-8 col-sm-12 fullWidth">
            <div class="form-group">
                <label for="gender">Gender</label>
                <b-form-select v-model="userPersonalInfo.gender" name="gender" class="form-control" :options="genderList" >
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Please select gender --</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
        </div>
        <div class="col-md-8 col-sm-12 fullWidth">
            <div class="form-group">
                <label for="citizenship">Country of Citizenship</label>
                <b-form-select v-model="userPersonalInfo.citizenship" :options="countriesList" name="citizenship" class="form-control">
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Please select country of citizenship --</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
        </div>
        <div class="col-md-8 col-sm-12 fullWidth">
            <div class="form-group">
                <label class="label-datepicker" for="dateOfBirth">Date of Birth</label>
                <date-picker v-model="userPersonalInfo.dateofBirth" value-type="format" format="YYYY-MM-DD"></date-picker>
                <!-- <b-form-datepicker 
                id="dateOfBirth"
                v-model="userPersonalInfo.dateofBirth"
                placeholder="Date of Birth"
                :max="maxDate"
                :min="minDate"
                ></b-form-datepicker> -->
            </div>
        </div>
        <div class="col-md-8 col-sm-12 fullWidth">
            <div class="form-group">
                <label for="txtMobileNumber">Phone</label>
                <input type="text" v-model="userPersonalInfo.toNumber" name="txtMobileNumber" class="form-control" placeholder="Your Mobile Number *" value="" />
                <!-- <b-form-datepicker 
                id="dateOfBirth"
                v-model="userPersonalInfo.dateofBirth"
                placeholder="Date of Birth"
                :max="maxDate"
                :min="minDate"
                ></b-form-datepicker> -->
            </div>
        </div>
        <div class="col-12 text-center btnDivMobile">
            <b-button size="md" type="submit" variant="outline-success">
                {{buttonText}}
            </b-button>
        </div>
        </form>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import moment from "moment";
import $ from 'jquery'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    props:['userId'],
    components: { DatePicker },
    data(){
        return{
            previewUrl: '',
            countriesList:null,
            allFilled:false,
            buttonText:'Save Personal Info',
            minDate:'',
            maxDate:'',
            userPersonalInfo:{
                user_id:this.userId,
                dateofBirth:'',
                residence:null,
                citizenship:null,
                gender:null,
                image_path:'',
                toNumber:'',
                url:'users/createUserPersonalInfo'
            },
            genderList:[
                {value:'Male',text:'Male'},
                {value:'Female',text:'Female'}
                ],
            loadFile:null,
        }
    },
    methods:{
        ...mapActions({
            fetchCountry: 'auth/fetchCountryList',
            UserPersonalInfofetch:'auth/fetchUserPersonalInfo',
            saveUserPersonalInfo:'auth/saveUserPersonalInfo',
            fileUpload:'speakmodule/uploadFile',
        }),
        ...mapGetters({
            getUserPersonalinfo: 'auth/getPersonalInfo',
            getCountriesList:'auth/getCountriesList'
        }),
        setMinMaxDate(){
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            this.minDate = new Date(today)
            //const maxDate = new Date(today)
            this.maxDate=new Date(today)
            //this.minDate.setMonth(this.minDate.getMonth())
            this.minDate.setMonth(this.minDate.getMonth()-560) //2 years back
        },
        getCountries(){
            this.fetchCountry('users/country').then(()=>{
                var response = this.getCountriesList();
                //console.log(response)
                this.countriesList=response.countries
            });
        },
        onFileChange: function (event) {
            this.loadFile=event.target.files
            const file = event.target.files[0]
            if (!file) {
                return false
            }
            if (!file.type.match('image.*')) {
                return false
            }
            const reader = new FileReader()
            const that = this
            reader.onload = function (e) {
                //console.log(e)
                that.previewUrl = e.target.result
            }
            
            reader.readAsDataURL(file)
        },
        userPersonalInfoReterive(){
            ////debugger
            var vm=this
            //console.log(this.userPersonalInfo.user_id+'Inside personal reterival')
            this.UserPersonalInfofetch('users/userid='+this.userPersonalInfo.user_id).then((res)=>{
                //console.log(res)
                ////debugger
                var response = vm.getUserPersonalinfo();
                //console.log(response)
                if(response.success!=1)
                {
                    vm.buttonText='Save Personal Info'
                }
                else{
                    vm.userPersonalInfo=response.data
                    vm.previewUrl=vm.userPersonalInfo.image_path
                    vm.userPersonalInfo.image_path=vm.previewUrl
                    //console.log(vm.previewUrl)
                    vm.userPersonalInfo.dateofBirth=vm.changeDate(vm.userPersonalInfo.dateofBirth,'shortDate')
                    vm.buttonText='Update Personal Info'
                }
            });
        },
        saveUpdateUserPersonalInfo(){
            ////debugger
            if(this.userPersonalInfo.image_path==''&this.loadFile==null){
                return this.$alert('Please add profile image')
            }
            else if(this.userPersonalInfo.dateofBirth=='' )
            {
                return this.$alert('Please select date of birth')
            }
            else if(this.userPersonalInfo.residence==null)
            {
                return this.$alert('Please select residence country')
            }
            else if(this.userPersonalInfo.citizenship==null )
            {
                return this.$alert('Please select citizen country')
            }
            else if(this.userPersonalInfo.gender==null)
            {
                return this.$alert('Please select gender')
            }
            //console.log(this.loadFile)
            if(this.loadFile!=null){

            var data={
                fileName:'userImage--',
                files:this.loadFile
            }
            data.fileName+= this.userPersonalInfo.user_id+'-'+this.loadFile[0].name.replace(/\.[^/.]+$/, "")
            this.userPersonalInfo.image_path='audio\\userImage\\userImage'+this.userPersonalInfo.user_id+'-'+this.loadFile[0].name
            }
            // else if(this.loadFile!=''){
            //     this.userPersonalInfo.image_path==this.loadFile
            // }
            this.userPersonalInfo.url=(this.buttonText=='Save Personal Info')?'users/createUserPersonalInfo':'users/updateUserPersonalInfo';
            this.userPersonalInfo.dateofBirth=this.changeDate(this.userPersonalInfo.dateofBirth,'shortDate')
            this.saveUserPersonalInfo(this.userPersonalInfo).then(()=>{
                var response= this.getUserPersonalinfo();
                if(response.success==1)
                {
                    if(this.loadFile!=null){
                    this.fileUpload(data)
                    }
                    this.buttonText='Update Personal Info'
                    this.$alert('User record saved successfully')
                }
                let getUser=this.$ls.get("user");
                getUser.toNumber=this.userPersonalInfo.toNumber;
                this.$ls.set("user",getUser);
                this.$browserStore.setLocal('backUser',JSON.stringify(this.$ls.get('user')))
            })
        },
        changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("Do MMMM YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("YYYY-MM-DD");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
        selectImage(){
            $('#imageInput').click()
        }
    },
    beforeMount(){
        this.setMinMaxDate()
        this.getCountries()
        this.userPersonalInfoReterive()
    }
}
</script>
<style scoped>
form{
    text-align: left;
}
form button{
    text-align: center;
}
.imageOutPut
{
    display: block;
    width: 150px;
    height: 170px;
    background: #ccc;
    text-align: center;
    margin: auto;
    cursor: pointer;
}
.imageOutPut img{
        display: block;
    object-fit: contain;
    height: 100%;
    width: 100%;
}
.imageOutPut p{
    position: relative;
    display: block;
    top: 80px;
    font-weight: 600;
    color: #000;
}
.imageDivOutPut
{
    display: block;
    position: absolute;
    float: right;
    right: 5%;
}
.label-datepicker
{
    display: block!important;
}
.mx-datepicker
{
    width: 100%;
    border-color: #d0d6db;
}
</style>