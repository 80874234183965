<template>
  <!--Pricing Table Section Start-->
  <section class="price-table fullwidth">
    <div class="container">
      <div class="heading-center">
        <h2>SELECT A PLAN</h2>
        <p>Choose from Free to Premium Plans to Practice PTE Test with Quality Practice Material and state of art AI (Artificial Intelligence) modules</p>
      </div>
      <div class="holder">
        <div class="row">
          <div class="col-lg-6 col-md-12 fullwidth">
            <div :class="{'default': user_type=='Free'}" class="box">
              <div class="head">
                <h3>FREE</h3>
                <span class="price">$0</span></div>
              <ul>
                <li><b>Website Access: </b>Unlimited </li>
                <li><b> Mock Test: </b>1 with no scoring</li>
                <li><b>Prediction Files: </b> No</li>
                <li><b>Writing Scoring: </b> 5 in total</li>
                <li><b>Speaking Scoring: </b> 5 per day</li>
                <li><b>Reading and Listening Scoring: </b> Unlimited</li>
              </ul>
              <a v-if="user_type== ''" @click="toggleModal('free')" class="btn-get">GET STARTED</a>
              <a v-if="user_type== 'Free'" href="#" class="btn-get">Current Plan</a></div>
          </div>
        
          <!-- No more standard plan,. -->
          <!-- <div class="col-lg-6 col-md-12 fullwidth">
            <div class="box" :class="{'default': user_type=='standard'}" v-on:mouseenter="defaultActive = false" v-on:mouseleave="defaultActive = true">
              <div class="head">
                <h3>STANDARD</h3>
                <span class="price" >${{standardAmount}}/Month</span>
                </div>
              <ul>
                <li><b>Website Access: </b>Unlimited </li>
                <li><b> Mock Test: </b>10 with no scoring</li>
                <li><b>Prediction Files: </b> No</li>
                <li><b>Writing Scoring; </b> 10 in total</li>
                <li><b>Speaking Scoring: </b> 10 per day</li>
                <li><b>Reading and Listening Scoring: </b> Unlimited</li>
              </ul>
              <a v-if="user_type == 'standard'"  @click="unSubscription()" class="btn-get">Unsubscribe</a>
              <a v-if="user_type != 'standard'" @click="toggleModal('standard')" id="standardPayment" class="btn-get">GET STARTED</a>
              
              </div>
          </div> -->
          <div class="col-lg-6 col-md-12 fullwidth">
            <div class="box" :class="{'default': user_type=='premium'}" v-on:mouseenter="defaultActive = false" v-on:mouseleave="defaultActive = true">
              <div v-if="premiumDiscountAmount!='0'" class="head">
                <h3>PREMIUM</h3>
                <span class="price" style="text-decoration: line-through;">${{ premiumAmount }}/Month</span>
                <span class="price discountPrice">$ {{ premiumDiscountAmount }}/Month</span> 
                </div>
                <div v-else class="head">
                <h3>PREMIUM</h3>
                <span class="price">${{ premiumAmount }}/Month</span>
                </div>
              <ul>
                <li><b>Website Access: </b>Unlimited </li>
                <li><b> Mock Test: </b>Unlimited</li>
                <li><b>Prediction Files: </b> Yes</li>
                <li><b>Writing Scoring: </b>Unlimited</li>
                <li><b>Speaking Scoring: </b>Unlimited</li>
                <li><b>Reading and Listening Scoring: </b> Unlimited</li>
              </ul>
              <a v-if="user_type != 'premium'" @click="toggleModal('premium')" id="premiumPayment" class="btn-get">GET STARTED</a>
              <a v-if="user_type == 'premium'" @click="unSubscription()" class="btn-get">Unsubscribe</a>
              </div>
          </div>
        </div>
          <div class="row" style="margin-top: 50px!important;">
          
          <!-- <div class="col-xl-6 col-lg-6 col-md-12 fullwidth">
            <div :class="{'default': userCoachingPackage =='1-Month'}" class="box" v-on:mouseenter="defaultActive = false" v-on:mouseleave="defaultActive = true">
              <div class="head">
                <h3>Pro Master Practice Class</h3>
                <span class="price">$20/Month</span></div>
                <ul>
                  <li><b>Masterclass Video Recordings. </b></li>
                  <li><b>Learn PTE from scratch. </b></li>
                <li><b>Get access to tested templates. </b> </li>
                <li><b>Learn how to use templates. </b> </li>
                <li><b>Tips and Tricks for PTE Exam. </b> </li>
                <li><b>One Time Subscription. </b></li>
                 </ul>
              <a v-if="userCoachingPackage!='1-Month'" @click="toggleModalCoaching('1-Month')" id="Month-1" class="btn-get">GET STARTED</a>
              <a v-if="userCoachingPackage =='1-Month'"  @click="unSubscriptionCoaching()" class="btn-get">Unsubscribe</a></div>
          </div> -->
          <!-- <div class="col-md-3">
            <div class="box" v-on:mouseenter="defaultActive = false" v-on:mouseleave="defaultActive = true">
              <div class="head">
                <h3>ENTERPRISE</h3>
                <span class="price">$20/Month</span></div>
              <ul>
                <li><b>Free</b> feature one</li>
                <li><b>Unlimited</b> feature two</li>
                <li><b>Unlimited</b> feature three</li>
                <li><b>2x</b> feature four</li>
                <li><b>100x</b> feature five</li>
              </ul>
              <a href="#" class="btn-get">GET STARTED</a></div>
          </div> -->
        </div>
      </div>
      <b-modal ref="payment-Modal" id="paymentModel" no-close-on-backdrop hide-footer hide-header centered>
        <b-button class="closeModalbutton" @click="$bvModal.hide('paymentModel')">X</b-button>
        <b-overlay class="fixedHeight mt-3" :show="show" rounded="sm" >
      <div class="mb-3 text-center">
        <h3 class="">Select Payment Gateway</h3>
      </div>
      <b-button :disabled="!agreedToTermsConditions" class="mb-3 stripeBtn" variant="outline-primary" block @click="stripAPIPayment" style="
    font-size: 20px!important;
    font-weight: 700!important;padding: 22px;
"><span style="
    font-size: 24px;
    font-weight: 800;
    color: #635bff;
">Stripe</span>/Debit or Credit Card
        <!-- <img src="@/assets/images/Stripelg.png" /> -->
      </b-button>
      <b-button :disabled="!agreedToTermsConditions" class="paypalButton" variant="outline-primary" block @click="payPalPayment">
        <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_200x51.png" alt="PayPal" />
        </b-button>
        <b-button :disabled="!agreedToTermsConditions" class="paypalButton" variant="success" block @click="directPayment">
          Direct Bank Transfer / RAAST
        </b-button>
        <hr/>
          <div class="row">
            <div class="col-lg-6 col-md-12 mt-2 pl-0">
          <div class="form-group">
                    <input type="text" v-model="discountVoucher" name="discountVoucher" placeholder="Discount Voucher Code" class="form-control" :class="{ 'is-invalid': !validCode }"/>
                   
                </div>
              </div>
                    <div class="col-lg-6 col-md-12 mt-2 pr-0">
                      
                        <b-button :disabled="!agreedToTermsConditions" @click="validateVoucher()" type="button" variant="info" class="p-2 pl-4" block>Redeem Voucher</b-button>
                    </div>
                    <div class="col-lg-12 pl-0">
                    <div v-if="showValidateMsg" class="valid-message" :class="{ 'invalid-message': !validCode }" >{{ validateMsg }}</div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <template>
                  <div>
                    <b-form-checkbox
                    :disabled="agreedToTermsConditions"
                      id="agreedCheckbox"
                      v-model="agreedToTermsConditions"
                      name="agreedCheckbox"
                      value="true"
                      unchecked-value="false"
                    >
                      I accept the <a href="https://pteclasses.com/terms-conditions/" target="_blank">terms and conditons</a> 
                     
                    </b-form-checkbox>
                  </div>
                </template>
                  </div>
                </div>
        <template #overlay>
        <div class="text-center">
          <b-icon icon="cloud-upload-fil" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Processing...</p>
        </div>
      </template>
    </b-overlay>
    </b-modal>
    <b-modal size="xl" v-model="detailshow" id="bankDetailModel" no-close-on-backdrop hide-footer hide-header centered>
        <b-button class="closeModalbutton" @click="detailshow=false" >X</b-button>
        <template>
          <table class="bankDetail">
  <tr>
    <th>TITLE</th>
    <th>DESCRIPTION</th>
  </tr>
  <tr>
    <td class="bld">TH Office</td>
    <td>Karachi Branch</td>
  </tr>
  <tr>
    <td class="bld">Account Name</td>
    <td>Aneel Khowaja</td>
  </tr>
  <tr>
    <td class="bld">IBAN</td>
    <td>PK27BAHL1004098106625701</td>
  </tr>
  <tr>
    <td class="bld">Account No</td>
    <td>1004098106625701</td>
  </tr>
  <tr>
    <td class="bld">Bank Name</td>
    <td>Bank Al Habib</td>
  </tr>
  <tr>
    <td class="bld">Bank Address</td>
    <td>1004-GULSHAN-E-IQBAL</td>
  </tr>
  <tr>
    <td class="bld">Swift Code</td>
    <td>BAHLPKKA</td>
  </tr>
  <tr>
    <td class="bld">RAAST ID</td>
    <td>01433217177</td>
  </tr>
  <tr>
    <td class="bld">IBAN</td>
    <td>PK27BAHL1004098106625701</td>
  </tr>
  <tr>
    <td class="bld">E-Mail / WhatsApp</td>
    <td>Please Mail your receipt at <strong>info@pteclasses.com</strong> or share transfer receipt at <strong>+92-302-2556108</strong> for the payment confirmation.</td>
  </tr>

          </table>
        </template>
        
    </b-modal>
    <b-modal ref="payment-Modal-Coaching" id="paymentModelCoaching" no-close-on-backdrop hide-footer hide-header centered>
        <b-button class="closeModalbutton" @click="$bvModal.hide('paymentModelCoaching')">X</b-button>
        <b-overlay class="fixedHeight mt-3" :show="show" rounded="sm" >
      <div class="mb-3 text-center">
        <h3 class="">Select Payment Gateway</h3>
      </div>
      <b-button class="mb-3" variant="outline-primary" block @click="stripAPIPaymentCoaching" style="
    font-size: 20px!important;
    font-weight: 700!important;
">
        <span style="
              font-size: 24px;
              font-weight: 800;
              color: #635bff;
          ">Stripe</span> / Debit or Credit Card
        <!-- <img src="@/assets/images/Stripelg.png" /> -->
      </b-button>
      <b-button class="paypalButton" variant="outline-primary" block @click="payPalPaymentCoaching">
        <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_200x51.png" alt="PayPal" />
        </b-button>
        <b-button class="paypalButton" variant="success" block @click="directPayment">
          Direct Bank Transfer / RAAST
        </b-button>
        <template #overlay>
        <div class="text-center">
          <b-icon icon="cloud-upload-fil" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Processing...</p>
        </div>
      </template>
    </b-overlay>
    </b-modal>
    </div>
  </section>
  <!--Pricing Table Section End-->
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>
// import {loadStripe} from '@stripe/stripe-js';
import { mapActions,mapGetters } from 'vuex';
//  const Stripe = require('stripe');
//  const stripePackage = Stripe('pk_live_51HBciXE2TwokhaFwzlSfqWSNM7DR2dv5Cx5fmHE9ecvEYpL45AluSWmBFFwVYXSyQcfbx6ULzulzri0mKLoH3XCs006fV3akpr');
export default {
  props:['UpdatePlanParent'],
  data () {
    return {
      discountVoucher:"",
      showValidateMsg:false,
      validCode:true,
      codeStatus:false,
      validateMsg:"",
      defaultActive: true,
      detailshow:false,
      standardAmount:7.99,
      premiumAmount:10.99,
      premiumDiscountAmount:0,
      price_id:'',
      stripe:null,
      user:null,
      user_type:'',
      stripeSetUpResponse:'',
      currentSelectedType:'',
      show:false,
      changePaymentType:'',
      userCoachingPackage:'',
      changePaymentTypeCoaching:'',
      currentSelectedTypeCoaching:'',
      currentPackageCoaching:'',
      price_idCoaching:'',
      packages:null,
      stripeStandardPriceId:process.env.VUE_APP_STANDARD_PRICE_ID,
      stripePremiumPriceId:process.env.VUE_APP_PREMIUM_PRICE_ID,
      paypalStandardPlanId:null,
      paypalPremiumPlanId:null,
      agreedToTermsConditions:false
    }
  },
  async mounted() {
      //this.stripe = await loadStripe('pk_live_51HBciXE2TwokhaFwzlSfqWSNM7DR2dv5Cx5fmHE9ecvEYpL45AluSWmBFFwVYXSyQcfbx6ULzulzri0mKLoH3XCs006fV3akpr');
      //this.stripe = await loadStripe('pk_test_51HBciXE2TwokhaFw2giSrMH54hGA1vPLHLZZHe7uG84lX5z5eOulwVpTYjrDLNZzF18lNgcGly66sUouOJ28w69f003YoBCHFU');
      //var _this=this;
      this.premiumDiscountAmount=process.env.VUE_APP_PREM_PROMOTION_PRICE;
    this.user = this.$ls.get('user');
    this.codeStatus=false;
   // console.log(this.user);
   // I set it 0 to get the default packages.
      const categoryId=(this.user.ref_user_cat_id==null)?0:this.user.ref_user_cat_id;
      console.log(categoryId);
    if(categoryId!=null){ 
      this.getPackagesById(categoryId).then(()=>{
        var result=this.packagesList();
        this.packages=result.packages;
        
        this.premiumAmount=this.packages[0].Category_Price;
        this.stripePremiumPriceId=this.packages[1].Package_Key;
        this.paypalPremiumPlanId=this.packages[0].Package_Key;

        this.standardAmount=this.packages[2].Category_Price;
        this.stripeStandardPriceId=this.packages[3].Package_Key;
        this.paypalStandardPlanId=this.packages[2].Package_Key;
        
        //console.log(result);
      });
    }
    else{
      this.premiumAmount=process.env.VUE_APP_PREM_ACTIVE_PRICE
    }
      this.user_type=(this.user !=null) ?this.user.user_type:'';
      //console.log(this.user)
      this.userCoachingPackage=(this.user !=null) ?this.user.coaching:'';
  },
  methods:{
    ...mapActions({
            updateUser: 'auth/updateUserType',
            stripActions: 'auth/stripActions',
            removeSession: 'auth/removeSession',
            reFetchUser:'auth/fetchUser',
            paypalQuery:'paypal/postPayPalQuery',
            getPackagesById: 'auth/getPackagesById',
            simpleResponse:'auth/fetchSimpleResponse'
        }),
        ...mapGetters({
         getUser:'auth/user',
         paypalResponse:'paypal/getPayPalResponse',
         packagesList:'auth/packages',
         getSimpleResponse:'auth/getSimpleResponse'

      }),
    // ...mapGetters({
    //         stripData: 'auth/getStrip'
    //     }),
    toggleModal(type) {
      this.agreedToTermsConditions=false;
        this.user = this.$ls.get('user');
        this.changePaymentType=''
        this.currentSelectedType=type
        var currentPackage=null
        if(this.user!=null) currentPackage=this.user.pte_payment_type
        if(this.user == null){
          return this.$alert(
                              "Please create you account first or login to proceed.",
                              "Warning",
                              "warning",
                              {
                                  confirmButtonText: "OK"
                              })
          //alert('Please create you account first or login to proceed')
        }
        else if(currentPackage !=null & currentPackage!='No'){
          return this.$confirm("Are you sure you want to change your subscription?",
                              "Confirmation",
                              "question",
                              {
                                  confirmButtonText: "Confirm"
                              }).then(()=>{
                                this.$refs['payment-Modal'].toggle('#standardPayment')
                                this.$refs['payment-Modal'].toggle('#premiumPayment')
                              })
        }
        else{
          this.$refs['payment-Modal'].toggle('#standardPayment')
          this.$refs['payment-Modal'].toggle('#premiumPayment')
        }
      },
      async validateVoucher(){
        this.showValidateMsg=true;
        this.codeStatus=false;
        this.validateMsg="Validating......";
        const url='/users/validateVoucher/code='+this.discountVoucher;
        this.simpleResponse(url).then(()=>{
          const result=this.getSimpleResponse();
          if(result!=null){
            console.log(result);
          this.codeStatus=this.validCode=result.valid;
          this.validateMsg=result.message;
          }
          else{
            this.validateMsg="Invalid/Expired Code.";
          }
        }).catch((err)=>{
          console.log(err);
          this.validateMsg="Invalid/Expired Code.";
        });
      },
      async updateSubScriptionCoaching(){
    if(this.changePaymentType=='Stripe')
        {
           this.show=true
          this.$confirm("Your current coaching subscription is on PayPal, if you select Stripe payment method, your PayPal coaching subsription will be cancelled?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Confirm"
                              }).then(()=>{
                                this.payPalCancelSub('renewal')
                              }).catch(() => {
                                this.show=false
                                //console.log('cancel')
                              });
        }
        else{
        var vm=this
        return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/update-subscription", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            priceId: vm.price_idCoaching,
            subscriptionId:vm.user.coaching_stripe_subscription,
          })
        }).then(function(result) {
          vm.$refs['payment-Modal-Coaching'].hide()
          ////console.log(result.json())
          result.json().then((res)=>{
            var data={
                    coaching_payment_type:'Stripe',
                    coaching_amount:res.subscription.plan.amount,
                    url:'users/updateChangeCoachingStripePackage',
                    user_id: vm.user.user_id,
                    coaching:vm.currentSelectedTypeCoaching,
                    coaching_payment_date:null,
                    coaching_stripe_membership:JSON.stringify(res),
                    email:vm.user.email_id,
                    userName:vm.user.first_name
                }
          //console.log(data)
          vm.updateUser(data).then(()=>{
            var userData={
                      email_id:data.email
                      }
                      vm.userCoachingPackage=''
                        vm.reFetchUser(userData).then(()=>{
                          vm.user= vm.$ls.get('user')
                          ////console.log(user)
                          if(vm.user!=null){
                                vm.userCoachingPackage=vm.user.coaching
                              }
                          window.location.reload()
                        })
                    ////console.log(response)
                })
          
          })
          
        });
        }
      },
      stripAPIPaymentCoaching(){
        this.show=true
        if(this.user.coaching_payment_type=="PayPal" & this.changePaymentTypeCoaching=='')
        {
          this.changePaymentTypeCoaching='Stripe'
        }
        if(this.currentSelectedTypeCoaching=='1-Month') 
        {
          this.price_idCoaching=process.env.VUE_APP_ONE_MONTH_COACHIG
        }
        else if(this.currentSelectedType=='3-Month'){
          this.price_idCoaching=process.env.VUE_APP_THREE_MONTH_COACHIG
        }
        else if(this.currentSelectedType=='6-Month'){
          this.price_idCoaching=process.env.VUE_APP_SIX_MONTH_COACHIG
        }
        else if(this.currentSelectedType=='12-Month'){
          this.price_idCoaching=process.env.VUE_APP_12_MONTH_COACHIG
        }
        if(this.user.coaching_payment_type=='Stripe' | this.changePaymentTypeCoaching=='Stripe'){
          return this.updateSubScriptionCoaching()
        }
        var vm=this;
        return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/setup")
        .then(this.handleFetchResult)
        .then(function(res) {
          ////console.log(res)
          
          vm.stripeSetUpResponse=res
          var stripe = Stripe(vm.stripeSetUpResponse.publishableKey);
          vm.createCheckoutSession(vm.price_idCoaching).then(function(data) {
            stripe
              .redirectToCheckout({
                sessionId: data.sessionId
              })
              .then(function(result) {
                //vm.show=false
                  //console.log(result)
                if (result.error) {
                  vm.$alert(
                      result.error.message,
                      "Error",
                      "error",
                      {
                        confirmButtonText: "Issue in Payment Process"
                      }
                    )
                  // alert(result.error.message);
                }
              })
              .catch(function(error) {
                this.show=false
                console.error('Error:', error);
              })
          });
          //return //console.log(result.json());
        });
      },
      toggleModalCoaching(type) {
        this.agreedToTermsConditions=false;
      this.changePaymentTypeCoaching=''
      this.currentSelectedTypeCoaching=type
     if(this.user!=null)this.currentPackageCoaching=this.user.coaching
      if(this.user == null){
        return this.$alert(
                            "Please create you account first or login to proceed.",
                            "Warning",
                            "warning",
                            {
                                confirmButtonText: "OK"
                            })
        //alert('Please create you account first or login to proceed')
      }
      else if(this.currentPackageCoaching !='No' & this.currentPackageCoaching!=null){
        return this.$confirm("Are you sure you want to change your subscription?",
                            "Confirmation",
                            "question",
                            {
                                confirmButtonText: "Confirm"
                            }).then(()=>{
                              
                              this.togglePaymentCoaching(type)
                            })
      }
      else{
        //this.$ls.set('CoachingPackageType',type)
        this.togglePaymentCoaching(type)
      }
    },
    updatePayPalPaymentCoaching(){
      ////debugger
      if(this.changePaymentTypeCoaching=="PayPal")
        {
          this.show=true
          this.$confirm("Your current coaching subscription is on Stripe, if you select PayPal payment method your Stripe coaching subsription will be cancelled?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Confirm"
                              }).then(()=>{
                                  var vm=this
                                    return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/cancel-subscription",{
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json"
                                      },
                                      body: JSON.stringify({
                                        subscriptionId: vm.user.coaching_stripe_subscription
                                      })
                                      }).then((results)=>{
                                          vm.changePaymentTypeCoaching='Free'
                                          vm.cancelsubUpdateUser(results.subscription,'PayPalchange')
                                      })
                              }).catch(() => {
                                this.show=false
                                //console.log('cancel')
                              });
        }
        else{
      var data={
        url:'payPalPayment/get-token'
      }
        var vm=this
        this.show=true
        vm.paypalQuery(data).then(()=>{
        let tokenResponse=vm.paypalResponse();
        if(tokenResponse.status==200){
          const PlanId=(vm.currentSelectedType=='standard')?vm.paypalStandardPlanId:vm.paypalPremiumPlanId;
          var updateSubdata={
            url:'payPalPayment/update-subscription',
            planType:vm.currentSelectedTypeCoaching,
            planMode:'live',
            paypal_subscription_id:vm.user.coaching_stripe_customerId,
            planId:PlanId
          }
          vm.paypalQuery(updateSubdata).then(()=>{
            let updateResponse=vm.paypalResponse()
            if(updateResponse.status==200){
                var updateUserdata={
                    coaching_payment_type:'PayPal',
                    coaching_amount:vm.$ls.get('packageAmount'),
                    url:'users/updateChangeCoachingStripePackage',
                    user_id: vm.user.user_id,
                    coaching:vm.currentSelectedTypeCoaching,
                    coaching_payment_date:null,
                    coaching_stripe_membership:JSON.stringify(updateResponse.response),
                    email:vm.user.email_id,
                    userName:vm.user.first_name
                }
                //console.log(updateUserdata)
                vm.updateUser(updateUserdata).then(()=>{
                    var userData={
                              email_id:data.email
                              }
                    vm.userCoachingPackage=''
                      vm.reFetchUser(userData).then(()=>{
                        vm.user= vm.$ls.get('user')
                        if(vm.user!=null){
                        vm.userCoachingPackage=vm.user.coaching
                        }
                        window.location.reload()
                      })
                          ////console.log(response)
                  })
          
            }
          });
        }
      });
        }
    },
    payPalPaymentCoaching(){
      ////debugger
      this.show=true
      if(this.user.coaching_payment_type=="Stripe" & this.changePaymentTypeCoaching=='' )
        {
          this.changePaymentTypeCoaching='PayPal'
        }
      if(this.currentSelectedTypeCoaching=='1-Month') 
        {
          this.$ls.set('packageAmount',199)
        }
        else if(this.currentSelectedTypeCoaching=='3-Month'){
          this.$ls.set('packageAmount',249)
        }
        else if(this.currentSelectedTypeCoaching=='6-Month'){
          this.$ls.set('packageAmount',399)
        }
        else if(this.currentSelectedTypeCoaching=='12-Month'){
          this.$ls.set('packageAmount',699)
        }
        if(this.user.coaching_payment_type=='PayPal' | this.changePaymentTypeCoaching=='PayPal'){
          return this.updatePayPalPaymentCoaching()
        }
      var data={
        url:'payPalPayment/get-token'
      }
      var vm=this
      vm.show=true
      vm.paypalQuery(data).then(()=>{
        let tokenResponse=vm.paypalResponse();
        if(tokenResponse.status==200){
          var subdata={
            url:'payPalPayment/subscribe-now',
            planType:vm.currentSelectedTypeCoaching,
            planMode:'live',
            first_name:vm.user.first_name,
            last_name:vm.user.last_name,
            email_id:vm.user.email_id
          }
            vm.paypalQuery(subdata).then(()=>{
              //vm.show=false
              let subResponse=vm.paypalResponse().response
              //console.log(subResponse)
                if(subResponse.status=='APPROVAL_PENDING'){
                  vm.$ls.set('paypalReturn',subResponse)
                  const redirectLink=subResponse.links[0].href
                  //console.log(redirectLink)
                  window.open(redirectLink,"_self")
                }
            })
        }
      })
    },
    togglePaymentCoaching(type){
    if(this.currentSelectedTypeCoaching=='') this.currentSelectedTypeCoaching=type
    ////console.log(this.price_id)
    //////debugger
     this.$refs['payment-Modal-Coaching'].toggle('#Month-1')
     this.$refs['payment-Modal-Coaching'].toggle('#Month-3')
     this.$refs['payment-Modal-Coaching'].toggle('#Month-6')
     this.$refs['payment-Modal-Coaching'].toggle('#Month-12')
  },
      async checkExistingPaymentType(){
        if(this.user.pte_payment_type=="PayPal")
        {
          this.$confirm("Your current subscription is with PayPal, if you select Stripe payment method your Paypal subsription will be cancelled?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Confirm"
                              }).then(()=>{
                                // yes
                              }).catch(() => {
                                //no
                              });
        }
        else if(this.user.pte_payment_type=="Stripe")
        {
          this.$confirm("Your current subscription is on Stripe, if you select PayPal payment method your Stripe subsription will be cancelled?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Confirm"
                              }).then(()=>{
                                //yes
                              }).catch(() => {
                                //no
                              });
        }
      },
      updateSubScription(){
        if(this.changePaymentType=='Stripe')
        {
           this.show=true
          this.$confirm("Your current subscription is on PayPal, if you select Stripe payment method, your PayPal subsription will be cancelled?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Confirm"
                              }).then(()=>{
                                this.payPalCancelSub('renewal')
                              }).catch(() => {
                                this.show=false
                                //console.log('cancel')
                              });
        }
        else{
            var vm=this
          return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/update-subscription", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                priceId: vm.price_id,
                subscriptionId:vm.user.stripe_subscription,
              })
            }).then(function(result) {
              vm.$refs['payment-Modal'].hide()
            ////console.log(result.json())
            result.json().then((res)=>{
              //console.log(res)
              var updatedStripeUser={
                      pte_payment_type:'Stripe',
                      amount:res.subscription.plan.amount,
                      url:'users/updateChangeStripePackage',
                      user_id: vm.user.user_id,
                      user_type:vm.currentSelectedType,
                      payment_date:null,
                      strip_membership:JSON.stringify(res),
                      email:vm.user.email_id,
                      user_type:vm.user.user_type,
                      userName:vm.user.first_name
                  }
                  updatedStripeUser.user_type=vm.currentSelectedType
              //console.log(updatedStripeUser)
              vm.updateUser(updatedStripeUser).then((response)=>{
                ////console.log(response)
                        vm.user.user_type = vm.user_type= vm.$parent.currentPlan= vm.currentSelectedType
                        vm.$ls.set('user',vm.user)
                        var userData={
                              email_id:updatedStripeUser.email
                              }
                        vm.reFetchUser(userData)
                        var start= new Date();
                        var end= new Date(start.getFullYear(), start.getMonth()+1, 1);
                        var days = (end- start) / (1000 * 60 * 60 * 24);
                        vm.$ls.set('remainingDays',days)
                        vm.$alert(
                          "Your New Plan is Updated, Start Practicing",
                          "Success",
                          "success",
                          {
                            confirmButtonText: "Congratulation, Let's Start!"
                          }
                        ).then(()=>{
                          window.location.reload()
                        })
                        
                        
                        ////console.log(response)
                    })
              })
              
            });
        }
        
      },
      async unSubscription(){
        this.user = this.$ls.get('user');
        //var vm=this
        this.$confirm(
              "Please confirm your unsubscribe request",
              "Unsubscribe",
              "question",
              {
                confirmButtonText: "Confirm"
              }
            ).then(()=>{
              if(this.user.pte_payment_type=='PayPal'){
                return this.payPalCancelSub('cancel')
              }
              var vm=this
              return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/cancel-subscription",{
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  subscriptionId: vm.user.stripe_subscription
                })
                }).then((results)=>{
                      vm.cancelsubUpdateUser(results.subscription,'unsubscribe')
                })
              })
      },
      handleFetchResult(result) {
        //console.log(result)
        if (!result.ok) {
          return result.json().then(function(json) {
            if (json.error && json.error.message) {
              //console.log(result.url + ' ' + result.status + ' ' + json.error.message);
            }
          }).catch(function(err) {
            //console.log(err);
          });
        }
        return result.json();
      },
      stripAPIPayment(){
        ////debugger
        this.show=true
        if(this.user.pte_payment_type=="PayPal" & this.changePaymentType=='')
        {
          this.changePaymentType='Stripe'
        }
        // this.price_id =(this.currentSelectedType=="standard")?process.env.VUE_APP_STANDARD_PRICE_ID:
        //                        this.price_id = process.env.VUE_APP_PREMIUM_PRICE_ID;
        this.price_id =(this.currentSelectedType=="standard")?this.stripeStandardPriceId:
                               this.price_id = this.stripePremiumPriceId;
        this.$ls.set('PackageType',this.currentSelectedType)
        if((this.user_type!='Free' & this.user.pte_payment_type!='Cash') | this.changePaymentType=='Stripe'){
          return this.updateSubScription()
        }
        var vm=this;
        return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/setup")
        .then(this.handleFetchResult)
        .then(function(res) {
          //console.log(res)
          vm.stripeSetUpResponse=res
          var stripe = Stripe(vm.stripeSetUpResponse.publishableKey);
          vm.createCheckoutSession(vm.price_id).then(function(data) {
            console.log(data.sessionId)
            stripe
              .redirectToCheckout({
                sessionId: data.sessionId
              })
              .then(function(result) {
                //vm.show=false
                  //console.log(result)
                if (result.error) {
                  vm.$alert(
                      result.error.message,
                      "Error",
                      "error",
                      {
                        confirmButtonText: "Issue in Payment Process"
                      }
                    )
                  // alert(result.error.message);
                }
              })
              .catch(function(error) {
                this.$alert('Issue on Payament process please contact site admin')
                this.show=false
                console.error('Error:', error);
              })
          });
          //return //console.log(result.json());
        });
      },
      cancelsubUpdateUser(result,type){
        var vm=this
        var data={
                      pte_payment_type:'No',
                      userName:vm.user.first_name,
                      email:vm.user.email_id,
                      user_id: vm.user.user_id,
                      user_type:'Free',
                      url:'users/userupdatetype',
                      payment_cancel:null,
                      cancel_membership:JSON.stringify(result)
                    }
                    var vvm= this
                    this.updateUser(data).then(()=>{
                      vvm.user.user_type =vvm.user_type= vvm.$parent.currentPlan= 'Free'
                      vvm.$ls.set('user',vvm.user)
                      if(type=='unsubscribe'){
                        vvm.removeSession().then(()=>{
                        vvm.$parent.currentUser = vvm.user = null
                        return window.location.reload()
                         //this.$root.$emit('bv::show::modal', 'login-Modal')
                        })
                      }
                      
                      //this.$ls.remove('PackageType')
                    })
      },
      createCheckoutSession(priceId) {
        //process.env.VUE_APP_BASE_URL+
        var vm=this
        const coupen=(this.codeStatus)?this.discountVoucher:"nothing";
        return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/create-checkout-session", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            priceId: priceId,
            customer_email:vm.user.email_id,
            customer:vm.user.stripe_customerId,
            payType:'pte',
            coupon:coupen
          })
        }).then(function(result) {
          return result.json();
        });
      },
      updatePayPalPayment(){
        if(this.changePaymentType=="PayPal")
        {
           this.show=true
          this.$confirm("Your current subscription is on Stripe, if you select PayPal payment method your Stripe subsription will be cancelled?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Confirm"
                              }).then(()=>{
                                  var vm=this
                                    return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/cancel-subscription",{
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json"
                                      },
                                      body: JSON.stringify({
                                        subscriptionId: vm.user.stripe_subscription
                                      })
                                      }).then((results)=>{
                                          vm.changePaymentType='Free'
                                          vm.cancelsubUpdateUser(results.subscription,'change')
                                            vm.payPalPayment()
                                      })
                              }).catch(() => {
                                this.show=false
                                //console.log('cancel')
                              });
        }
        else{
          ////debugger
            this.show=true
          var data={
            url:'payPalPayment/get-token'
          }
          var vm=this
          vm.paypalQuery(data).then(()=>{
            let tokenResponse=vm.paypalResponse();
            if(tokenResponse.status==200){
              var updateSubdata={
                url:'payPalPayment/update-subscription',
                planType:vm.currentSelectedType,
                planMode:'live',
                paypal_subscription_id:vm.user.stripe_customerId
              }
              vm.paypalQuery(updateSubdata).then(()=>{
                //vm.show=false
                let updateResponse=vm.paypalResponse()
                if(updateResponse.status==200){
                    var updateUserdata={
                        pte_payment_type:'PayPal',
                        amount:vm.$ls.get('packageAmount'),
                        url:'users/updateChangeStripePackage',
                        user_id: vm.user.user_id,
                        user_type:vm.currentSelectedType,
                        payment_date:null,
                        strip_membership:JSON.stringify(updateResponse.response),
                        email:vm.user.email_id,
                        user_type:vm.user.user_type,
                        userName:vm.user.first_names
                    }
                      //console.log(updateUserdata)
                vm.updateUser(updateUserdata).then((response)=>{
                //vm.show=false
                        vm.user.user_type = vm.user_type= vm.$parent.currentPlan= vm.currentSelectedType
                        vm.$ls.set('user',vm.user)
                        var userData={
                              email_id:data.email
                              }
                        vm.reFetchUser(userData)
                        // var start= new Date();
                        // var end= new Date(start.getFullYear(), start.getMonth()+1, 1);
                        // var days = (end- start) / (1000 * 60 * 60 * 24);
                        // vm.$ls.set('remainingDays',days)
                        window.location.reload()
                        ////console.log(response)
                    })
              
                }
              });
            }
          });
        }
      },
      payPalCancelSubCoaching(status){
      var data={
        url:'payPalPayment/get-token'
      }
      var vm=this
      vm.paypalQuery(data).then(()=>{
        let tokenResponse=vm.paypalResponse();
        if(tokenResponse.status==200){
          var canceldata={
            url:'payPalPayment/cancel-subscription',
            planType:vm.currentSelectedType,
            planMode:'live',
            paypal_subscription_id:vm.user.coaching_stripe_customerId
          }
          vm.paypalQuery(canceldata).then(()=>{
            let cancelResponse=vm.paypalResponse()
            if(cancelResponse.status==200)
            {
              if(status=='cancel'){
              vm.cancelsubUpdateUser(cancelResponse.response,'unsubscribe')
              //window.location.reload()
              }
              else{
                this.changePaymentType='Free'
                this.user_type='Free'
                vm.cancelsubUpdateUser(cancelResponse.response,'strieChange')
              }
            }
          })
        }
      });
    },
   unSubscriptionCoaching(){
        this.$confirm(
              "Please confirm your unsubscribe request",
              "Unsubscribe",
              "question",
              {
                confirmButtonText: "Confirm"
              }
            ).then(()=>{
              if(this.user.coaching_payment_type=='PayPal')
              {
                return this.payPalCancelSubCoaching()
              }
              var vm=this
              return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/cancel-subscription",{
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  subscriptionId: vm.user.coaching_stripe_subscription
                })
                }).then((results)=>{
                      vm.cancelsubUpdateUserCoaching(results.subscription,'unsubscribe')
                })
              })
      },
      cancelsubUpdateUserCoaching(result,type){
            var data={
                coaching_payment_type:'No',
                userName:this.user.first_name,
                email:this.user.email_id,
                user_id: this.user.user_id,
                coaching:'No',
                url:'users/updateUserCoachingType',
                coaching_payment_cancel:null,
                coaching_cancel_membership:JSON.stringify(result)
              }
              this.updateUser(data).then(()=>{
                var userData={
                email_id:data.email
                }
                this.userCoachingPackage=''
                  this.reFetchUser(userData).then(()=>{
                    this.user= this.$ls.get('user')
                    if(this.user!=null){
                        this.userCoachingPackage=this.user.coaching
                        if(type=='unsubscribe'){
                          window.location.reload()
                        }
                        else if(type=='PayPalchange')
                        {
                          this.payPalPayment()
                        }
                        else if(type=='stripeChange')
                        {
                          this.stripAPIPayment()
                        }
                      }
                  })
              })
    },
   directPayment(){
      this.$refs['payment-Modal'].hide()
      this.detailshow=true
   },
    payPalPayment(){
      this.show=true
      if(this.user.pte_payment_type=="Stripe" & this.changePaymentType=='' )
        {
          this.changePaymentType='PayPal'
        }
      if(this.currentSelectedType=='standard') 
        {
          this.$ls.set('packageAmount',this.standardAmount)
        }
        else if(this.currentSelectedType=='premium'){
          this.$ls.set('packageAmount',this.premiumAmount)
        }
        if(this.user.pte_payment_type=='PayPal' | this.changePaymentType=='PayPal'){
          return this.updatePayPalPayment()
        }
      var data={
        url:'payPalPayment/get-token'
      }
      var vm=this
      vm.paypalQuery(data).then(()=>{
        let tokenResponse=vm.paypalResponse();
        //console.log(tokenResponse)
        if(tokenResponse.status==200){
          let PlanId=(vm.currentSelectedType=='standard')?vm.paypalStandardPlanId:vm.paypalPremiumPlanId;
          debugger
          if(vm.codeStatus & vm.currentSelectedType!='standard'){
            PlanId='P-3TC94480JF188274PMWIM77I';
          }
          var subdata={
            url:'payPalPayment/subscribe-now',
            planType:vm.currentSelectedType,
            planMode:'live',
            first_name:vm.user.first_name,
            last_name:vm.user.last_name,
            email_id:vm.user.email_id,
            planId:PlanId
          }
            vm.paypalQuery(subdata).then(()=>{
              let subResponse=vm.paypalResponse().response
              //console.log(subResponse)
              //vm.show=false
                if(subResponse.status=='APPROVAL_PENDING'){
                  vm.$ls.set('paypalReturn',subResponse)
                  const redirectLink=subResponse.links[0].href
                  //console.log(redirectLink)
                  window.open(redirectLink,"_self")
                }
            })
        }
      })
    },
    payPalCancelSub(status){
      var data={
        url:'payPalPayment/get-token'
      }
      var vm=this
      vm.paypalQuery(data).then(()=>{
        let tokenResponse=vm.paypalResponse();
        //console.log(tokenResponse)
        if(tokenResponse.status==200){
          var canceldata={
            url:'payPalPayment/cancel-subscription',
            planType:vm.currentSelectedType,
            planMode:'live',
            paypal_subscription_id:vm.user.stripe_customerId
          }
          vm.paypalQuery(canceldata).then(()=>{
            let cancelResponse=vm.paypalResponse()
            if(cancelResponse.status==200)
            {
              if(status=='cancel'){
                vm.cancelsubUpdateUser(cancelResponse.response,'unsubscribe')
              }
              else{
                vm.cancelsubUpdateUser(cancelResponse.response,'change')
                this.changePaymentType='Free'
                this.user_type='Free'
                vm.stripAPIPayment()
              }
            }
          })
        }
      });
    }
  },
  watch: { 
        UpdatePlanParent: function(newVal, oldVal) { // watch it
        this.user_type = newVal
          //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        }
      }
}
</script>
<style scoped>
.valid-message{
  color: green;
    width: 100%;
    font-size: 90%;
    display: block;
    font-weight: 600;
}
.invalid-message{
  color: #d92550;
}
.stripeBtn:hover span{
color: #dddddd!important;
}
.userProfile.m-3 .price-table .box{
  min-height: 550px;
}
.price-table .box ul{
  min-height: 175px!important;
}
.box.default {
  border-color: transparent;
  background: #1e467f;
  transform: scale(1.05);
  z-index: 9;
  position: relative;
}

.price-table .box.default a.btn-get {
  border-color: transparent;
  color: #fff;
  background: #f23276;
    border-radius: 50px;
    padding: 8px 15px;
    box-shadow: 2px 4px 0px grey;
}

.box.default ul li b, .price-table .box.default h3, .box.default ul li, .box.default .price {
  color: #fff !important;
}
#paymentModel img,#paymentModelCoaching img
{
  width: 35%;
}
/* #paymentModel button{
  background: none!important;
} */
.paypalButton
{
  padding-top: 20px!important;
  padding-bottom: 20px!important;

}
.closeModalbutton, .closeModalbutton:hover,.closeModalbutton:active,.closeModalbutton:focus{
  color: #f30f00;
    padding: 5px!important;
    line-height: 10px;
    font-weight: 800;
    position: absolute;
    right: 10px;
    top: 10px;
    border-color: #f30f00;
    z-index:5;
}
.oldPrice {
      text-decoration: line-through!important;
    font-size: 12px!important;
}
.box.default .discountPrice,span.discountPrice{
  color: #ffcc33!important;
    font-weight: 600!important;
}
table.bankDetail  {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size:14px;
}
.bankDetail td,.bankDetail th {
  border: 1px solid #000;
  text-align: left;
  padding: 8px;
  min-width:150px;
  
}
.bankDetail td.bld{
font-weight: 600;
}
.bankDetail th{
font-size:16px!important;
}
.bankDetail tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
